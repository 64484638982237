.container {
  display: flex;
  margin: 0;
  padding: 0;

  list-style: none;

  & li {
    margin-right: 32px;
  }
}
